<template>
  <v-form v-model="formIsValid" ref="form" @submit.prevent="submitForm">
    <v-row class="flex-column">
      <v-col>
        <v-select
          v-model="criteria.operator"
          :items="availableOperators"
          label="Condición"
          :rules="[$data.$globalRules.required]"
        />
        <v-text-field
          v-model="criteria.value"
          :rules="[$data.$globalRules.required, $data.$globalRules.number]"
          label="Número a filtrar"
        />
      </v-col>
      <v-col class="text-right">
        <slot name="actions"></slot>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      criteria: {
        operator: null,
        value: null,
      },
      formIsValid: null,
      availableOperators: [
        { text: "Igual a", value: "e" },
        { text: "Más que", value: "gt" },
        { text: "Menos que", value: "lt" },
        { text: "Contiene", value: "c" },
        //{ text: "Mayor o igual que", value: "" },
        //{ text: "Menor o igual que", value: "" },
        //{ text: "Diferente de", value: "ne" },
        //{ text: "Empieza por", value: "sw" },
        //{ text: "Termina por", value: "ew" },
      ],
    };
  },
  methods: {
    submitForm() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        this.$emit("submitForm", this.criteria);
      }
    },
  },
};
</script>

<style scoped></style>
